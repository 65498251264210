<div class="container mt-2">
    <div class="row">
        <div class="col align-content-center">
            <h2 class="featurette-heading"
                i18n>
                Research
            </h2>

            <p i18n>eduCACE is committed to removing barriers to our work by sharing what we’re working on and maintaining timely, open access to all our research products.</p>

            <p i18n>Look below to learn more about what we’re working on!</p>
        </div>
    </div>

    <hr />

    <div class="row">
        <div class="col">
            <h2 class="featurette-heading"
                i18n>
                Competency-based testing and credentialing
            </h2>

            <p i18n>Competency-based testing and credentialing (CBTC) initiatives are designed to address inequity in adult education by reforming policies governing how states award and withhold high school equivalency (HSE) credentials based on HSE test scores.</p>

            <p i18n>This peer-reviewed article supports state-led efforts by answering the foundational question, “Do CBTC policies reflect a valid use of HSE test scores?”</p>
        </div>
    </div>

    <hr />

    <div class="row">
        <div class="col">
            <h2 class="featurette-heading"
                i18n>
                Interdeveloper mobility
            </h2>

            <p i18n>Interdeveloper mobility -- high school equivalency (HSE) candidates’ movement from one developer’s HSE test battery to another’s -- reflects a persistent barrier to candidate’s access to state-issued HSE credentials.</p>

            <p i18n>The research report advances how adult education stakeholders understand the causes of interdeveloper mobility.</p>
        </div>
    </div>
</div>
