import { Route } from '@angular/router';
import { HomeComponent } from './home/component';
import { ResearchComponent } from './research/component';

export const ROUTES: Route[] = [
    {
        path: 'research',
        component: ResearchComponent,
        title: "Research",
    }, {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    },
];
