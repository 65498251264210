<div class="container mt-2">
    <div class="row">
        <div class="col align-content-center">
            <h2 class="featurette-heading"
                i18n>
                eduCACE | The Center for Adult and Continuing Education
            </h2>

            <p i18n>Founded in 2024, eduCACE aims to advance adult education research, policy, and practice through partnerships with local, state, and national adult education stakeholders.</p>
        </div>
    </div>

    <hr />

    <div class="row">
        <div class="col">
            <h2 class="featurette-heading"
                i18n>
                About Us
            </h2>

            <p i18n>Come back soon to learn more about our founding members!</p>

            <p i18n>Until then, please reach out to our Administrative Team at <a href="mailto:admin@educace.org">admin&#64;educace.org</a> with any questions.</p>
        </div>
    </div>

    <hr />

    <div class="row">
        <div class="col">
            <h2 class="featurette-heading"
                i18n>
                Our Work
            </h2>

            <p i18n>Learn more about our expanding research efforts and published articles!</p>

            <a class="btn btn-primary mb-3"
               routerLink="/research">Research Papers</a>

            <p i18n>Interested in developing a research partnership? Please contact our Administrative Team at <a href="mailto:admin@educace.org">admin&#64;educace.org</a> to share your interests!</p>
        </div>
    </div>
</div>
